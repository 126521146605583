.icon-primary{
    color: $primary-color;
}
.icon-info{
    color: $info-color;
}
.icon-success{
    color: $success-color;
}
.icon-warning{
    color: $warning-color;
}
.icon-danger{
    color: $danger-color;
}
.icon-neutral{
    color: $white-color;
}
.info{
    padding: 30px 0 30px;
    text-align: center;
    // max-width: 400px;
    margin: 0 auto;

    .icon{
        margin-top: 0;
        font-size: 3.4em;
        &.icon-sm{
            font-size: $font-size-h4;
        }
        .fa{
            width: 48px;
        }
    }
    h4,
    .h4{
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.info-horizontal{
    text-align: left;
    margin-top: 0;

    .icon{
        float: left;
        margin-top: 5px;
        margin-right: 20px;
        font-size: $font-size-info-horizontal;

        .fa{
            width: 48px;
        }
    }
    .description{
       border: 1px solid red;
        overflow: hidden;
    }
    .btn{
        margin-left: -19px;
    }
}

.awards {
  padding-top:10px;
}

.info li {
  &:before {
    content: '';
    display: inline-block;
    height: 1em;
    width: 1em;
    background-image: url(https://res.cloudinary.com/no10labs/image/upload/v1617386368/ChevronRightArrow.svg); // Fallback PNG
    background-image: url(https://res.cloudinary.com/no10labs/image/upload/v1617386368/ChevronRightArrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-right:0.5em;   
  }
}

.info li {
    display: flex;
    align-items: center;
      @media (max-width: 768px) {
        justify-content:center;
  }
}

.list-with-heading-left {
  text-align: left;
  padding-left: 220px;
      @media (max-width: 1200px) and (min-width:995px) {
      padding-left: 100px;
  }
      @media (max-width: 995px) and (min-width:768px) {
      padding-left: 70px;
  }
      @media (max-width: 768px) {
      padding-left: 0px;
      text-align: center;
  }
}

.list-with-heading-right {
  text-align: left;
  padding-left: 150px;
      @media (max-width: 995px) and (min-width:768px) {
      padding-left: 70px;
  }
      @media (max-width: 768px) {
      padding-left: 0px;
      text-align: center;
  }
}

.info ul {
    @media (max-width: 768px) {
    padding-left: 0px;
    text-align: center;
    }
}

.list-with-heading-left h4 {
    padding-left: 3px;
    @media (max-width: 768px) {
    padding-left: 20px;
    }
}

.list-with-heading-right h4 {
    padding-left: 3px;
    @media (max-width: 768px) {
    padding-left: 20px;
    }
      
}





